import React, { useEffect } from 'react'
import {
  List,
  Drawer,
  ListItemText,
  ListItemIcon,
  Box,
  Divider,
  ListItemButton,
  Collapse,
} from '@material-ui/core'
import logo from 'app/Assets/brackets_logo_nav.svg'
import dashboardIcon from 'app/Assets/dashboard_ic.svg'
import usersIcon from 'app/Assets/users_ic.svg'
import attendanceIcon from 'app/Assets/attendance_ic.svg'
import payrollIcon from 'app/Assets/payroll_ic.svg'
import tax from 'app/Assets/tax.svg'
import taxSlabsIcon from 'app/Assets/taxSlabs_ic.svg'
import requestIcon from 'app/Assets/requests_ic.svg'
import projectsIcon from 'app/Assets/projects_ic.svg'
import departmentsIcon from 'app/Assets/departments_ic.svg'
import designationsIcon from 'app/Assets/designations_ic.svg'
import positionsIcon from 'app/Assets/positions_ic.svg'
import holidaysIcon from 'app/Assets/holidays_ic.svg'
import shiftsIcon from 'app/Assets/shifts_ic.svg'
import accountIcon from 'app/Assets/account_ic.svg'
import generalConfigurationIcon from 'app/Assets/generalConfiguration_ic.svg'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import api from 'app/AxiosInstance'
import { useDispatch, useSelector } from 'react-redux'
import { applyRequestsCount } from 'app/Redux/slices/user'
import { applyAttendanceFilter } from 'app/Redux/slices/user'
import { toggleDrawer } from 'app/Redux/slices/user/Drawer'
import { useMediaQuery } from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import expenseIcon from 'app/Assets/expense_ic.svg'
import Image from 'mui-image'
import closeDrawerIcon from '../../Assets/back_ic.svg'

import BusinessIcon from '@mui/icons-material/Business'
import AssignmentIcon from '@mui/icons-material/Assignment'
import LaptopIcon from '@mui/icons-material/Laptop'

import profitReport from 'app/Assets/profitReport.png'

import AssessmentIcon from '@mui/icons-material/Assessment'

import {
  isAdminOfficer,
  isCEO,
  isInternee,
  isHRExecutive,
  isManagement,
} from 'app/Utils/helper'

const Navigator = () => {
  const history = useHistory()
  const attendanceId = localStorage.getItem(`loggedInAttendanceId`)
  const loggedInEmployeeName = localStorage.getItem(`loggedInEmployeeName`)
  const [firstName, lastName] = loggedInEmployeeName.split(` `)
  const fullName = `${firstName}-${lastName}`

  const dispatch = useDispatch()
  const AttendanceFilter = useSelector((state) => state.filterAttendance)
  const requestsCount = useSelector((state) => state.requestCount)
  const drawerState = useSelector((state) => state.drawer)
  const colors = useSelector((state) => state.colors)

  const location = useLocation()

  const sendGetRequest = () => {
    api
      .get(`/users/user/notifications`)
      .then((response) => {
        dispatch(applyRequestsCount(response.data.data))
      })
      .catch((error) => {
        console.error(`Error sending GET request: `, error.message)
      })
  }

  const itemsList = isInternee()
    ? [
        {
          text: `Attendance`,
          icon: <img src={attendanceIcon} alt="Attendance" />,
          onclick: () => {
            if (isManagement()) {
              history.push(`/attendance`)
              sendGetRequest()
              dispatch(
                applyAttendanceFilter({
                  attendanceLog: `Monthly`,
                  Date: AttendanceFilter.Date,
                  monthYear: AttendanceFilter.monthYear,
                }),
              )
            } else {
              history.push(
                `/attendance/attendanceIndividual/${attendanceId}/${fullName}`,
              )
              sendGetRequest()
            }
          },
          newTab: () => {
            if (isManagement()) {
              window.open(`/attendance`, `_blank`)

              sendGetRequest()
              dispatch(
                applyAttendanceFilter({
                  attendanceLog: `Monthly`,
                  Date: AttendanceFilter.Date,
                  monthYear: AttendanceFilter.monthYear,
                }),
              )
            } else {
              window.open(
                `/attendance/attendanceIndividual/${attendanceId}/${fullName}`,
                `_blank`,
              )
              sendGetRequest()
            }
          },
        },
        {
          text: `Requests`,
          icon: <img src={requestIcon} alt="Requests" />,
          onclick: () => {
            history.push(`/requests`)
            sendGetRequest()
          },
          newTab: () => {
            window.open(`/requests`, `_blank`)
            sendGetRequest()
          },
        },
      ]
    : [
        {
          text: `Dashboard`,
          icon: <img src={dashboardIcon} alt="Dashboard" />,
          onclick: () => {
            history.push(`/dashboard`)
            sendGetRequest()
          },
          newTab: () => {
            window.open(`/dashboard`, `_blank`)
            sendGetRequest()
          },
        },
        {
          text: `Users`,
          icon: <img src={usersIcon} alt="User" />,
          onclick: () => {
            history.push(`/users`)
            sendGetRequest()
          },
          newTab: () => {
            window.open(`/users`, `_blank`)
            sendGetRequest()
          },
        },

        {
          text: `Attendance`,
          icon: <img src={attendanceIcon} alt="Attendance" />,
          onclick: () => {
            if (isManagement() || isHRExecutive()) {
              history.push(`/attendance`)
              sendGetRequest()
              dispatch(
                applyAttendanceFilter({
                  attendanceLog: `Monthly`,
                  Date: AttendanceFilter.Date,
                  monthYear: AttendanceFilter.monthYear,
                }),
              )
            } else {
              history.push(
                `/attendance/attendanceIndividual/${attendanceId}/${fullName}`,
              )
              sendGetRequest()
            }
          },
          newTab: () => {
            if (isManagement()) {
              window.open(`/attendance`, `_blank`)

              sendGetRequest()
              dispatch(
                applyAttendanceFilter({
                  attendanceLog: `Monthly`,
                  Date: AttendanceFilter.Date,
                  monthYear: AttendanceFilter.monthYear,
                }),
              )
            } else {
              window.open(
                `/attendance/attendanceIndividual/${attendanceId}/${fullName}`,
                `_blank`,
              )
              sendGetRequest()
            }
          },
        },
        // {
        //   text: `attendance by date`,
        //   icon: <img src={payrollIcon} alt="attendance by date" />,
        //   onclick: () => {
        //     history.push(`/attendanceByDate`)
        //     sendGetRequest()
        //   },
        //   newTab: () => {
        //     window.open(`/attendanceByDate`, `_blank`)
        //     sendGetRequest()
        //   },
        // },
        {
          text: `Payroll`,
          icon: <img src={payrollIcon} alt="Payroll" />,
          onclick: () => {
            history.push(`/payroll`)
            sendGetRequest()
          },
          newTab: () => {
            window.open(`/payroll`, `_blank`)
            sendGetRequest()
          },
        },
        {
          text: `Requests`,
          icon: <img src={requestIcon} alt="Requests" />,
          onclick: () => {
            history.push(`/requests`)
            sendGetRequest()
          },
          newTab: () => {
            window.open(`/requests`, `_blank`)
            sendGetRequest()
          },
        },
        {
          text: `Assessment`,
          icon: <AssessmentIcon sx={{ color: `#fff` }} />,
          onclick: () => {
            history.push(`/assessments`)
            sendGetRequest()
          },
          newTab: () => {
            window.open(`/assessments`, `_blank`)
            sendGetRequest()
          },
        },
        {
          text: `Tax`,
          icon: <img src={tax} alt="Tax" />,
          subMenu: [
            {
              text: `Tax Detail`,
              icon: <img src={tax} alt="Tax" />,
              onclick: () => {
                history.push(`/taxDetails`)
                sendGetRequest()
              },
              newTab: () => {
                window.open(`/taxDetails`, `_blank`)
                sendGetRequest()
              },
            },
            {
              text: `Tax Slabs`,
              icon: <img src={taxSlabsIcon} alt="Tax Slabs" />,
              onclick: () => {
                history.push(`/taxSlabs`)
                sendGetRequest()
              },
              newTab: () => {
                window.open(`/taxSlabs`, `_blank`)
                sendGetRequest()
              },
            },
          ],
        },
        {
          text: `Recruitment`,
          icon: <img src={requestIcon} alt="recruitment" />,
          subMenu: [
            {
              text: `Applicants`,
              icon: <img src={requestIcon} alt="applicants" />,
              onclick: () => {
                history.push(`/applicants`)
                sendGetRequest()
              },
              newTab: () => {
                window.open(`/applicants`, `_blank`)
                sendGetRequest()
              },
            },
            {
              text: `Business`,
              icon: <BusinessIcon sx={{ color: `white` }} />,
              onclick: () => {
                history.push(`/businessDeveloperApplicant`)
                sendGetRequest()
              },
              newTab: () => {
                window.open(`/businessDeveloperApplicant`, `_blank`)
                sendGetRequest()
              },
            },
          ],
        },
        {
          text: `Settings`,
          icon: <img src={generalConfigurationIcon} alt="General Config" />,
          subMenu: [
            {
              text: `Projects`,
              icon: <img src={projectsIcon} alt="Projects" />,
              onclick: () => {
                history.push(`/projects`)
                sendGetRequest()
              },
              newTab: () => {
                window.open(`/projects`, `_blank`)
                sendGetRequest()
              },
            },
            {
              text: `Teams`,
              icon: <img src={usersIcon} alt="Teams" />,
              onclick: () => {
                history.push(`/teams`)
                sendGetRequest()
              },
              newTab: () => {
                window.open(`/teams`, `_blank`)
                sendGetRequest()
              },
            },
            {
              text: `Departments`,
              icon: <img src={departmentsIcon} alt="Departments" />,
              onclick: () => {
                history.push(`/departments`)
                sendGetRequest()
              },
              newTab: () => {
                window.open(`/departments`, `_blank`)
                sendGetRequest()
              },
            },
            {
              text: `Designations`,
              icon: <img src={designationsIcon} alt="Designations" />,
              onclick: () => {
                history.push(`/designations`)
                sendGetRequest()
              },
              newTab: () => {
                window.open(`/designations`, `_blank`)
                sendGetRequest()
              },
            },
            {
              text: `Positions`,
              icon: <img src={positionsIcon} alt="Positions" />,
              onclick: () => {
                history.push(`/positions`)
                sendGetRequest()
              },
              newTab: () => {
                window.open(`/positions`, `_blank`)
                sendGetRequest()
              },
            },
            {
              text: `Holidays`,
              icon: <img src={holidaysIcon} alt="Holidays" />,
              onclick: () => {
                history.push(`/holidays`)
                sendGetRequest()
              },
              newTab: () => {
                window.open(`/holidays`, `_blank`)
                sendGetRequest()
              },
            },
            {
              text: `Shifts`,
              icon: <img src={shiftsIcon} alt="Shifts" />,
              onclick: () => {
                history.push(`/shifts`)
                sendGetRequest()
              },
              newTab: () => {
                window.open(`/shifts`, `_blank`)
                sendGetRequest()
              },
            },
            isManagement() && {
              text: `Contracts`,
              icon: <AssignmentIcon sx={{ color: `white` }} />,
              onclick: () => {
                history.push(`/contracts`)
                sendGetRequest()
              },
              newTab: () => {
                window.open(`/contracts`, `_blank`)
                sendGetRequest()
              },
            },
            isManagement() && {
              text: `Assets`,
              icon: <LaptopIcon sx={{ color: `white` }} />,
              onclick: () => {
                history.push(`/assets`)
                sendGetRequest()
              },
              newTab: () => {
                window.open(`/assets`, `_blank`)
                sendGetRequest()
              },
            },
          ].filter(Boolean), // This will remove any false or undefined values
        },

        {
          text: `General`,
          icon: <img src={usersIcon} alt="General Config" />,
          subMenu: [
            {
              text: `Quotations`,
              icon: <img src={usersIcon} alt="Quotes" />,
              onclick: () => {
                history.push(`/quotations`)
                sendGetRequest()
              },
              newTab: () => {
                window.open(`/quotations`, `_blank`)
                sendGetRequest()
              },
            },
            {
              text: `Gallery`,
              icon: <img src={usersIcon} alt="Quotes" />,
              onclick: () => {
                history.push(`/gallery`)
                sendGetRequest()
              },
              newTab: () => {
                window.open(`/gallery`, `_blank`)
                sendGetRequest()
              },
            },
            isManagement() &&
              !isAdminOfficer() && {
                text: `Configuration`,
                icon: (
                  <img src={generalConfigurationIcon} alt="General Config" />
                ),
                onclick: () => {
                  history.push(`/configuration`)
                  sendGetRequest()
                },
                newTab: () => {
                  window.open(`/configuration`, `_blank`)
                  sendGetRequest()
                },
              },
            isManagement() && {
              text: `QR Code`,
              icon: <img src={requestIcon} alt="qr code" />,
              onclick: () => history.push(`/QRCode`),
              newTab: () => {
                window.open(`/QRCode`, `_blank`)
              },
            },
          ].filter(Boolean),
        },
      ]

  if (isManagement() && !isAdminOfficer()) {
    itemsList?.splice(6, 0, {
      text: `Finance`,
      icon: <img src={payrollIcon} alt="accounts" />,
      subMenu: [
        {
          text: `Dashboard`,
          icon: <img src={dashboardIcon} alt="accounts" />,
          onclick: () => {
            history.push(`/ExpenditureAnalytics`)
            sendGetRequest()
          },
        },
        // Clients and Invoices will be hidden for EMPLOYEE, HRM and CTO
        ...(isCEO()
          ? [
              {
                text: `Clients`,
                icon: <img src={usersIcon} alt="clients" />,
                onclick: () => history.push(`/clients`),
                newTab: () => {
                  window.open(`/clients`, `_blank`)
                },
              },
              {
                text: `Business`,
                icon: <img src={usersIcon} alt="Business" />,
                onclick: () => history.push(`/business`),
                newTab: () => {
                  window.open(`/business`, `_blank`)
                },
              },
              {
                text: `Invoices`,
                icon: <img src={requestIcon} alt="income" />,
                onclick: () => history.push(`/income`),
                newTab: () => {
                  window.open(`/income`, `_blank`)
                },
              },
            ]
          : []),
        {
          text: `Accounts`,
          icon: <img src={accountIcon} alt="accounts" />,
          onclick: () => {
            history.push(`/accounts`)
            sendGetRequest()
          },
          newTab: () => {
            window.open(`/accounts`, `_blank`)
            sendGetRequest()
          },
        },
        {
          text: `Funds`,
          icon: <img src={accountIcon} alt="funds" />,
          onclick: () => {
            history.push(`/funds`)
            sendGetRequest()
          },
          newTab: () => {
            window.open(`/funds`, `_blank`)
            sendGetRequest()
          },
        },
        {
          text: `Expense`,
          icon: <img src={expenseIcon} alt="Expense" />,
          onclick: () => {
            history.push(`/expenses`)
            sendGetRequest()
          },
          newTab: () => {
            window.open(`/expenses`, `_blank`)
            sendGetRequest()
          },
        },
        {
          text: `Loan Report`,
          icon: <img src={profitReport} alt="loan-report" />,
          onclick: () => {
            history.push(`/loanReport`)
            sendGetRequest()
          },
          newTab: () => {
            window.open(`/loanReport`, `_blank`)
            sendGetRequest()
          },
        },
      ],
    })
  }

  !isInternee() &&
    itemsList
      .find((item) => item.text === `Recruitment`)
      .subMenu.push({
        text: `Jobs`,
        icon: <img src={projectsIcon} alt="Jobs" />,
        onclick: () => {
          history.push(`/jobs`)
          sendGetRequest()
        },
        newTab: () => {
          window.open(`/jobs`, `_blank`)
          sendGetRequest()
        },
      })

  const handleItemClick = (event, item) => {
    if (window.innerWidth <= 900) {
      if (event.ctrlKey) {
        item.newTab()
      } else {
        item.onclick()
      }
      dispatch(toggleDrawer({ openDrawer: !drawerState.openDrawer }))
    } else {
      if (event.ctrlKey) {
        item.newTab()
      } else {
        item.onclick()
      }
    }
  }

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1200 && drawerState.openDrawer) {
        dispatch(toggleDrawer({ openDrawer: !drawerState.openDrawer }))
      }
      if (window.innerWidth > 1200 && !drawerState.openDrawer) {
        dispatch(toggleDrawer({ openDrawer: !drawerState.openDrawer }))
      }
    }

    // Add an event listener for window resize
    window.addEventListener(`resize`, handleResize)

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener(`resize`, handleResize)
    }
  }, [drawerState.openDrawer])

  const isSmallScreen = useMediaQuery(`(max-width:900px)`)

  const [openSubMenus, setOpenSubMenus] = useState(itemsList?.map(() => false))

  const handleClick = (index) => {
    const newOpenSubMenus = [...openSubMenus]
    newOpenSubMenus[index] = !newOpenSubMenus[index]
    setOpenSubMenus(newOpenSubMenus)
  }

  return (
    <Drawer
      variant={isSmallScreen ? `temporary` : `persistent`}
      anchor="left"
      open={drawerState.openDrawer}
      onClose={() =>
        dispatch(toggleDrawer({ openDrawer: !drawerState.openDrawer }))
      }
      PaperProps={{
        sx: {
          width: `100%`,
          maxWidth: `250px`,
          backgroundColor: colors.appColor,
        },
      }}
    >
      <Box
        className="scrollbar-hide"
        sx={{
          display: `flex`,
          flexDirection: `column`,
          gap: `20px`,
          overflowY: `auto`,
        }}
      >
        <Box
          sx={{
            width: `100%`,
            height: `40px`,
            margin: `auto`,
            paddingX: `10px`,

            marginTop: `30px`,
            display: `flex`,
            justifyContent: `space-between`,
            alignItems: `center`,
          }}
        >
          <img src={logo} alt="logo" />
          <Image
            src={closeDrawerIcon}
            width={50}
            height={50}
            onClick={() =>
              dispatch(toggleDrawer({ openDrawer: !drawerState.openDrawer }))
            }
            sx={{ cursor: `pointer` }}
          />
        </Box>
        <Divider
          sx={{
            height: `2px`,
            margin: `25px`,
            marginTop: `10px`,
            background: `linear-gradient(90deg, transparent, lightgrey, transparent)`,
          }}
        />
        <List>
          {itemsList?.map((item, index) => (
            <React.Fragment key={item?.text}>
              {item?.subMenu ? (
                <>
                  <ListItemButton
                    onClick={() => handleClick(index)}
                    sx={{
                      padding: `10px`,
                      color: `white`,
                      '&:hover': {
                        cursor: `pointer`,
                        backgroundColor: location?.pathname
                          .toLowerCase()
                          ?.includes(item?.text?.toLowerCase())
                          ? `#19C9D1`
                          : `rgba(40, 32, 61, 0.4)`,
                      },
                      paddingLeft: `20px`,
                      backgroundColor: location?.pathname
                        .toLowerCase()
                        ?.includes(item?.text?.toLowerCase())
                        ? `#19C9D1`
                        : colors.appColor,
                    }}
                  >
                    <ListItemIcon>{item?.icon}</ListItemIcon>
                    <ListItemText primary={item?.text} />
                    {openSubMenus[index] ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse
                    in={openSubMenus[index]}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      {item.subMenu.map((subMenuItem) => (
                        <ListItemButton
                          key={subMenuItem?.text}
                          sx={{
                            padding: `5px`,
                            color: `white`,
                            '&:hover': {
                              cursor: `pointer`,
                              backgroundColor: location?.pathname
                                .toLowerCase()
                                ?.includes(
                                  subMenuItem?.text
                                    ?.toLowerCase()
                                    .replace(/\s/g, ``),
                                )
                                ? `#19C9D1`
                                : `rgba(40, 32, 61, 0.4)`,
                            },
                            paddingLeft: `40px`,
                            backgroundColor: location?.pathname
                              ?.toLowerCase()
                              ?.includes(
                                subMenuItem?.text
                                  ?.toLowerCase()
                                  ?.replace(/\s/g, ``),
                              )
                              ? `#19C9D1`
                              : colors.appColor,
                          }}
                          onClick={(event) =>
                            handleItemClick(event, subMenuItem)
                          }
                        >
                          <ListItemIcon>{subMenuItem?.icon}</ListItemIcon>
                          <ListItemText primary={subMenuItem?.text} />
                        </ListItemButton>
                      ))}
                    </List>
                  </Collapse>
                </>
              ) : (
                <ListItemButton
                  key={item?.text}
                  // selected={item.selected}
                  onClick={(event) => handleItemClick(event, item)}
                  sx={{
                    padding: `10px`,
                    color: `white`,
                    '&:hover': {
                      cursor: `pointer`,
                      backgroundColor: location?.pathname
                        .toLowerCase()
                        ?.includes(item?.text?.toLowerCase())
                        ? `#19C9D1`
                        : `rgba(40, 32, 61, 0.4)`,
                    },
                    paddingLeft: `20px`,
                    backgroundColor: location?.pathname
                      .toLowerCase()
                      ?.includes(item?.text?.toLowerCase())
                      ? `#19C9D1`
                      : colors.appColor,
                  }}
                >
                  <ListItemIcon>{item?.icon}</ListItemIcon>
                  <ListItemText primary={item?.text} />
                  {item?.text === `Requests` && requestsCount?.totalForms && (
                    <ListItemIcon>
                      <Box
                        sx={{
                          display: `flex`,
                          justifyContent: `center`,
                          alignItems: `center`,
                          width: `max-content`,
                          padding: `10px`,
                          height: `20px`,
                          borderRadius: `5px`,
                          backgroundColor: `rgba(25, 201, 209, 1)`,
                          color: `white`,
                        }}
                      >
                        {requestsCount?.totalForms}
                      </Box>
                    </ListItemIcon>
                  )}
                </ListItemButton>
              )}
            </React.Fragment>
          ))}
        </List>
      </Box>
    </Drawer>
  )
}

export default Navigator
