import React, { useState, useContext, useRef } from 'react'
import btmsIllustration from 'app/Assets/btmsIllustration.svg'
import bracketsLogo from 'app/Assets/brackets_logo.svg'
import axios from 'axios'
import jwtDecode from 'jwt-decode'
import { baseUrl } from 'app/Utils/Config'
import {
  Box,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Typography,
  TextField,
  CircularProgress,
  useMediaQuery,
} from '@material-ui/core'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { Redirect } from 'react-router-dom'
import Error from '../Error'
import { useMutation } from 'react-query'
import api from 'app/AxiosInstance'
import { useDispatch } from 'react-redux'
import { applyRequestsCount } from 'app/Redux/slices/user'
// import { applyColors } from 'app/Redux/slices/user'
import { toggleDrawer } from 'app/Redux/slices/user/Drawer'
import { SnackbarContext } from 'app/Components/SnackbarProvider'
import { useSelector } from 'react-redux'
import { changeSelectedItem } from 'app/Redux/slices/user/Drawer'
import { setBreadCrumbs } from 'app/Redux/actions'
import { isInternee } from 'app/Utils/helper'

const Login = () => {
  const verySmallScreen = useMediaQuery(`(max-width:600px)`)
  const dispatch = useDispatch()
  const colors = useSelector((state) => state.colors)
  const openSnackbar = useContext(SnackbarContext)
  const [showPassword, setShowPassword] = useState(false)
  const [userEmail, setUserEmail] = useState()
  const [userPassword, setUserPassword] = useState()
  const [errorDialogOpen, setErrorDialogOpen] = useState(true)
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  const loginRequest = async (payLoad) => {
    const response = await axios.post(`${baseUrl}/users/login`, payLoad)
    const Token = response.data.data.token
    const user = jwtDecode(Token)
    localStorage.setItem(`AuthToken`, Token)
    localStorage.setItem(`loggedInEmployeeName`, response.data.data.name)
    localStorage.setItem(`designation`, response.data.data.designation)
    localStorage.setItem(`systemRole`, response.data.data.systemRole)
    localStorage.setItem(`profilePicture`, response.data.data.profilePicture)
    localStorage.setItem(`loggedInUserId`, response.data.data._id)
    localStorage.setItem(
      `loggedInAttendanceId`,
      response.data.data.attendanceId,
    )
    localStorage.setItem(`userID`, user._id)

    dispatch(changeSelectedItem({ selectedItem: `Dashboard` }))
  }

  const attendanceId = localStorage.getItem('loggedInAttendanceId')
  const loggedInEmployeeName = localStorage.getItem('loggedInEmployeeName')

  const sendGetRequest = () => {
    api
      .get(`/users/user/notifications`)
      .then((response) => {
        setRequestedForms(response.data.data.totalForms)

        dispatch(applyRequestsCount(response.data.data.allPendingForms))
      })
      .catch((error) => {
        console.error(`Error sending GET request: `, error.message)
      })
  }

  // useQuery(
  //   `configurations`,
  //   async () => {
  //     const { data } = await api.get(`/configurations`)
  //     dispatch(
  //       applyColors({
  //         appColor: data.data[0].appColor,
  //       }),
  //     )

  //     return data.data
  //   },
  //   {
  //     refetchOnWindowFocus: false,
  //     cacheTime: 1,
  //     onError: (error) => {
  //       openSnackbar(
  //         error?.response ? error.response.data.message : error.message,
  //         `error`,
  //       )
  //     },
  //   },
  // )

  const {
    mutate,
    isLoading: isMutating,
    isError: mutationError,
    error: mutationErrorDetails,
  } = useMutation(loginRequest, {
    onSuccess: () => {
      setIsLoggedIn(true)
      dispatch(setBreadCrumbs([{ title: `Dashboard`, path: `/dashboard` }]))
      const month = new Date().getMonth()
      const year = new Date().getFullYear()
      localStorage.setItem(`month`, `${year}-0${month}`)
      openSnackbar(`Logged In Successfully`, `success`)
      sendGetRequest()
      if (verySmallScreen) {
        dispatch(toggleDrawer({ openDrawer: false }))
      }
    },
    onError: () => {
      setErrorDialogOpen(true)
    },
  })

  // show password
  const handleClickShowPassword = () => setShowPassword((show) => !show)

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  // close error modal
  const handleErrorClose = () => {
    setErrorDialogOpen(false)
  }

  const [sendEmail, setSendEmail] = useState()
  const handleSendEmailChange = (value) => {
    setSendEmail(value)
  }

  const [formErrors, setFormErrors] = useState({})
  const validate = () => {
    let hasErrors = false
    const errors = {}

    if (!forgetPassword) {
      if (!userEmail) {
        errors.userEmail = true
        hasErrors = true
      }

      if (!userPassword) {
        errors.userPassword = true
        hasErrors = true
      }
    } else {
      if (!sendEmail) {
        errors.sendEmail = true
        hasErrors = true
      }
    }

    setFormErrors(errors)
    return hasErrors
  }

  // handle login button click
  const handleLoginClick = () => {
    const hasErrors = validate()

    if (hasErrors) {
      openSnackbar(
        `Please provide your email address and password. Thank you.`,
        `error`,
      )
      return
    }

    if (!isMutating) {
      const payLoad = {
        email: userEmail,
        password: userPassword,
      }
      mutate(payLoad)
    }
  }

  const sendEmailRequest = async (payLoad) => {
    const response = await axios.post(
      `${baseUrl}/users/forgotPassword`,
      payLoad,
    )
    return response
  }

  const { mutate: sendEmailMutation, isLoading: isMutatingSendEmail } =
    useMutation(sendEmailRequest, {
      onSuccess: (response) => {
        openSnackbar(response.data.data, `success`)
      },
      onError: (error) => {
        openSnackbar(
          error?.response ? error.response.data.message : error.message,
          `error`,
        )
      },
    })

  const handleSendEmailClick = () => {
    const hasErrors = validate()
    if (hasErrors) {
      openSnackbar(`Kindly provide your email address, please.`, `error`)
      return
    }

    if (!isMutating) {
      const payLoad = {
        email: sendEmail,
      }
      sendEmailMutation(payLoad)
    }
  }

  // handle email change
  const handleUserEmailChange = (value) => {
    setUserEmail(value)
  }

  // handle password change
  const handleUserPasswordChange = (value) => {
    setUserPassword(value)
  }

  const loginRef = useRef(null)
  // handle enter key press
  const handleKeyDown = (event) => {
    if (forgetPassword) {
      if (event.key === `Enter`) {
        let hasErrors = validate()
        if (!hasErrors) {
          loginRef.current.click()
        } else {
          openSnackbar(`Kindly provide your email address, please.`, `error`)
        }
      }
    } else {
      if (event.key === `Enter`) {
        let hasErrors = validate()
        if (!hasErrors) {
          loginRef.current.click()
        } else {
          openSnackbar(
            `Please provide your email address and password. Thank you.`,
            `error`,
          )
        }
      }
    }
  }

  const [forgetPassword, setForgetPassword] = useState(false)
  const handleForgetPassword = () => {
    setForgetPassword(!forgetPassword)
  }

  // const [wallpaper, setWallpaper] = useState(null)
  // useQuery(
  //   `allPictures`,
  //   async () => {
  //     const { data } = await axios.get(`${baseUrl}/gallery`)
  //     const pictures = data.data
  //     if (pictures) {
  //       for (let pic of pictures) {
  //         if (pic.setAsWallpaper === true) {
  //           setWallpaper(pic.picture)
  //         }
  //       }
  //     }
  //     return data.data
  //   },
  //   {
  //     refetchOnWindowFocus: false,
  //     cacheTime: 1,
  //     onError: (error) => {
  //       openSnackbar(
  //         error?.response ? error.response.data.message : error?.message,
  //         `error`,
  //       )
  //     },
  //   },
  // )

  return (
    <>
      {isLoggedIn && isInternee() && (
        <Redirect
          to={`/attendance/attendanceIndividual/${attendanceId}/${loggedInEmployeeName}`}
        />
      )}
      {isLoggedIn && !isInternee() && <Redirect to="/dashboard" />}

      {mutationError && (
        <Error
          open={errorDialogOpen}
          onClose={handleErrorClose}
          Error={
            mutationErrorDetails?.response
              ? mutationErrorDetails.response.data.message
              : mutationErrorDetails.message
          }
        />
      )}
      <Box
        sx={{
          overflowY: `auto`,
          height: `100vh`,
          width: `100vw`,
          display: `flex`,
          flexDirection: `row`,
        }}
      >
        {useMediaQuery(`(min-width:900px)`) && (
          <Box
            sx={{
              height: `100vh`,
              width: {
                xs: `100%`,
                sm: `100%`,
                md: `50%`,
                lg: `50%`,
                xl: `50%`,
              },
              backgroundColor: `#6039BB`,
              display: `flex`,
              justifyContent: `center`,
              alignItems: `center`,
              padding: `20px`,
            }}
          >
            <Box
              sx={{
                display: `flex`,
                flexDirection: `column`,
                gap: `10px`,
                width: `100%`,
                background: `#6039BB`,
              }}
            >
              <Typography
                sx={{
                  fontSize: {
                    xs: 15,
                    sm: 20,
                    md: 25,
                    lg: 30,
                    xl: 35,
                  },
                  fontWeight: `bold`,
                  ml: `2rem`,
                  color: `#19C9D1`,
                }}
              >
                Simple. Robust.
              </Typography>

              <Typography
                sx={{
                  fontSize: {
                    xs: 15,
                    sm: 20,
                    md: 25,
                    lg: 30,
                    xl: 35,
                  },
                  fontWeight: `bold`,
                  ml: `2rem`,
                  color: `#19C9D1`,
                }}
              >
                Efficient. Secure.
              </Typography>

              <Box
                sx={{
                  margin: `auto`,
                  width: `40%`,
                  background: `none`,
                  mt: `6rem`,
                }}
              >
                <img src={btmsIllustration} alt="Logo" />
              </Box>
            </Box>
          </Box>
        )}

        <Box
          sx={{
            height: `100vh`,
            width: { xs: `100%`, sm: `100%`, md: `50%`, lg: `50%`, xl: `50%` },
            display: `flex`,
            justifyContent: `center`,
            alignItems: `center`,
            paddingY: `20px`,
          }}
        >
          <Box
            sx={{
              display: `flex`,
              flexDirection: `column`,
              gap: {
                xs: `10%`,
                sm: `20%`,
                md: `30%`,
                lg: `40%`,
                xl: `50%`,
              },
              width: `100%`,
            }}
          >
            <Box
              sx={{
                margin: `auto`,
                width: { xs: `50%`, sm: `30%` },
                height: `60px`,
                marginTop: {
                  xs: `40px`,
                  sm: `50px`,
                  md: `60px`,
                  lg: `70px`,
                  xl: `80px`,
                },
              }}
            >
              <img src={bracketsLogo} alt="Logo" className="w-full" />
            </Box>

            <Box
              sx={{
                display: `flex`,
                flexDirection: `column`,
                justifyContent: `center`,
                alignItems: `center`,
                margin: `auto`,
                width: { xs: `80%`, sm: `50%` },
                gap: `20px`,
              }}
            >
              {!forgetPassword ? (
                <Box
                  sx={{
                    width: `100%`,
                    display: `flex`,
                    flexDirection: `column`,
                    gap: `30px`,
                    marginTop: `30px`,
                  }}
                >
                  <Typography
                    align="center"
                    sx={{
                      fontSize: {
                        xs: 20,
                        sm: 20,
                        md: 30,
                        lg: 30,
                        xl: 35,
                      },
                      fontWeight: 600,
                    }}
                  >
                    Login
                  </Typography>
                  <FormControl sx={{ width: `100%` }}>
                    <TextField
                      size="small"
                      label="Email *"
                      value={userEmail}
                      onChange={(event) =>
                        handleUserEmailChange(event.target.value)
                      }
                      InputLabelProps={{
                        style: {
                          color: formErrors.userEmail
                            ? `#FF485E`
                            : userEmail
                            ? colors.appColor
                            : `rgba(40, 32, 61, 0.5)`,
                        },
                      }}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '&:hover fieldset': {
                            border: formErrors.userEmail
                              ? `solid #FF485E 1px`
                              : `solid ${colors.appColor} 1px`,
                          },
                          '&.Mui-focused fieldset': {
                            border: formErrors.userEmail
                              ? `solid #FF485E 1px`
                              : `solid ${colors.appColor} 1px`,
                          },
                          '& fieldset': {
                            borderColor: formErrors.userEmail
                              ? `#FF485E`
                              : userEmail
                              ? colors.appColor
                              : `rgba(40, 32, 61, 0.25)`,
                            borderRadius: `10px`,
                          },
                        },
                      }}
                    />
                  </FormControl>
                  <FormControl
                    size="small"
                    sx={{
                      width: `100%`,
                      '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                          border: formErrors.userPassword
                            ? `solid #FF485E 1px`
                            : `solid ${colors.appColor} 1px`,
                        },
                        '&.Mui-focused fieldset': {
                          border: formErrors.userPassword
                            ? `solid #FF485E 1px`
                            : `solid ${colors.appColor} 1px`,
                        },
                        '& fieldset': {
                          borderColor: formErrors.userPassword
                            ? `#FF485E`
                            : userPassword
                            ? colors.appColor
                            : `rgba(40, 32, 61, 0.25)`,
                          borderRadius: `10px`,
                        },
                      },
                    }}
                  >
                    <InputLabel
                      // htmlFor="outlined-adornment-password"
                      InputLabelProps={{
                        style: {
                          color: formErrors.userPassword
                            ? `#FF485E`
                            : userPassword
                            ? colors.appColor
                            : `rgba(40, 32, 61, 0.5)`,
                        },
                      }}
                    >
                      Password *
                    </InputLabel>
                    <OutlinedInput
                      // id="outlined-adornment-password"
                      type={showPassword ? `text` : `password`}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                      variant="outlined"
                      label="Password *"
                      value={userPassword}
                      onKeyDown={handleKeyDown}
                      onChange={(event) =>
                        handleUserPasswordChange(event.target.value)
                      }
                    />
                  </FormControl>
                  <Typography
                    align="right"
                    sx={{
                      fontFamily: `Inter`,
                      color: `#19C9D1`,
                      fontWeight: 600,
                      fontSize: { xs: 12, sm: 12, ms: 14, lg: 16, xl: 18 },
                      '&:hover': {
                        cursor: `pointer`,
                      },
                    }}
                    onClick={() => handleForgetPassword()}
                  >
                    Forget Password?
                  </Typography>
                  <Box
                    ref={loginRef}
                    variant="contained"
                    sx={{
                      width: `100%`,
                      height: `40px`,
                      color: `white`,
                      background: colors.appColor,
                      fontWeight: 500,
                      fontSize: { xs: 12, sm: 14, ms: 16, lg: 18, xl: 20 },
                      borderRadius: `10px`,
                      display: `flex`,
                      justifyContent: `center`,
                      alignItems: `center`,
                      '&:hover': { cursor: `pointer`, opacity: 0.8 },
                    }}
                    onClick={handleLoginClick}
                  >
                    {isMutating ? (
                      <CircularProgress
                        size={25}
                        color="secondary"
                        style={{ position: `absolute`, color: `white` }}
                      />
                    ) : (
                      `Login`
                    )}
                  </Box>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: `flex`,
                    flexDirection: `column`,
                    gap: `30px`,
                    marginTop: `30px`,
                  }}
                >
                  <Typography
                    align="center"
                    sx={{
                      fontSize: {
                        xs: 20,
                        sm: 20,
                        md: 30,
                        lg: 30,
                        xl: 35,
                      },
                      fontWeight: `bold`,
                    }}
                  >
                    Reset Password
                  </Typography>

                  <Typography
                    align="center"
                    sx={{
                      fontSize: {
                        xs: 12,
                        sm: 12,
                        md: 16,
                        lg: 19,
                        xl: 22,
                      },
                      color: `rgba(40, 32, 61, 0.4)`,
                    }}
                  >
                    Enter the email address associated with your account we’ll
                    send you a link to reset your password
                  </Typography>

                  <FormControl>
                    <TextField
                      size="small"
                      label="Email *"
                      value={sendEmail}
                      onKeyDown={handleKeyDown}
                      onChange={(event) =>
                        handleSendEmailChange(event.target.value)
                      }
                      InputLabelProps={{
                        style: {
                          color: formErrors.sendEmail
                            ? `#FF485E`
                            : sendEmail
                            ? colors.appColor
                            : `rgba(40, 32, 61, 0.5)`,
                        },
                      }}
                      sx={{
                        width: `100%`,
                        '& .MuiOutlinedInput-root': {
                          '&:hover fieldset': {
                            border: formErrors.sendEmail
                              ? `solid #FF485E 1px`
                              : `solid ${colors.appColor} 1px`,
                          },
                          '&.Mui-focused fieldset': {
                            border: formErrors.sendEmail
                              ? `solid #FF485E 1px`
                              : `solid ${colors.appColor} 1px`,
                          },
                          '& fieldset': {
                            borderColor: formErrors.sendEmail
                              ? `#FF485E`
                              : sendEmail
                              ? colors.appColor
                              : `rgba(40, 32, 61, 0.25)`,
                            borderRadius: `10px`,
                          },
                        },
                      }}
                    />
                  </FormControl>
                  <Box
                    ref={loginRef}
                    variant="contained"
                    sx={{
                      width: `100%`,
                      height: `40px`,
                      color: `white`,
                      background: colors.appColor,
                      fontWeight: 500,
                      fontSize: { xs: 12, sm: 14, ms: 16, lg: 18, xl: 20 },
                      borderRadius: `10px`,
                      display: `flex`,
                      justifyContent: `center`,
                      alignItems: `center`,
                      '&:hover': { cursor: `pointer`, opacity: 0.8 },
                    }}
                    onClick={handleSendEmailClick}
                  >
                    {isMutatingSendEmail ? (
                      <CircularProgress
                        size={25}
                        color="secondary"
                        style={{ position: `absolute`, color: `white` }}
                      />
                    ) : (
                      `Send Email`
                    )}
                  </Box>

                  <Typography
                    fontFamily="Inter"
                    align="center"
                    sx={{
                      color: `#19C9D1`,
                      fontWeight: 600,
                      fontSize: { xs: 12, sm: 12, ms: 14, lg: 16, xl: 18 },
                      '&:hover': {
                        cursor: `pointer`,
                      },
                    }}
                    onClick={() => handleForgetPassword()}
                  >
                    Back To Login
                  </Typography>
                </Box>
              )}

              <Typography
                sx={{
                  fontSize: {
                    xs: 12,
                    sm: 12,
                    md: 14,
                    lg: 16,
                    xl: 18,
                  },
                  fontWeight: 400,
                  color: `#28203D`,
                  opacity: 0.4,
                  textAlign: `center`,
                  marginBottom: {
                    xs: `40px`,
                    sm: `50px`,
                    md: `60px`,
                    lg: `70px`,
                    xl: `80px`,
                  },
                }}
              >
                © 2023 Brackets, All rights reserved
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default Login
